import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";

import Spinner from "../../../components/loader/Spinner.js";
import ConfirmationModal from "../../../components/modal/ConfirmationModal.js";
import { useActivitiesRepository } from "../../../repositories/activities/activitiesRepository.js";
import { useCompaniesRepository } from "../../../repositories/companies/companiesRepository.js";
import activitySchema from "../schemas/activitySchema.js";

function UpdateActivityForm() {
  const { activity_id } = useParams();
  const { activitiesRepository } = useActivitiesRepository();
  const { companies } = useCompaniesRepository();
  const [activity, setActivity] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchActivity() {
      const activityData = await activitiesRepository.getActivityById(
        activity_id
      );
      setActivity(activityData);
    }
    fetchActivity();
  }, [activity_id, activitiesRepository]);

  const { register, handleSubmit, formState } = useForm({
    mode: "onTouched",
    resolver: zodResolver(activitySchema),
  });

  const { errors, isValid } = formState;

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleUpdateActivity = async (form) => {
    // Añadir la propiedad modified_at con el tiempo actual
    form.modified_at = new Date().toISOString();

    try {
      await activitiesRepository.updateActivity(activity_id, form);
      toast.success("Actividad actualizada correctamente");
      navigate("/activities");
    } catch (error) {
      console.error(error);
      toast.error("Error al actualizar la actividad");
    }
  };

  const onInvalid = (errors) => console.error(errors);

  const handleDeleteActivity = async () => {
    try {
      await activitiesRepository.deleteActivity(activity_id);
      toast.success("Actividad eliminada correctamente");
      navigate("/activities");
    } catch (error) {
      console.error(error);
      toast.error("Error al eliminar la actividad");
    }
  };

  if (!activity) {
    return <Spinner />;
  }
  return (
    <form onSubmit={handleSubmit(handleUpdateActivity, onInvalid)}>
      <div className="space-y-12">
        <div className="border-b border-gray-900/10 pb-12 max-w-4xl mx-auto">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Modificar una actividad
          </h2>
          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-4">
              <label
                htmlFor="activity_name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Nombre
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <input
                    id="activity_name"
                    name="activity_name"
                    type="text"
                    defaultValue={activity?.activity_name}
                    {...register("activity_name")}
                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  />
                </div>
                <p className="h-4 text-sm text-red-500">
                  {errors.activity_name && errors.activity_name.message}
                </p>
              </div>
            </div>
            <div className="sm:col-span-3">
              <label
                htmlFor="role"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Organización
              </label>
              <div className="mt-2">
                <select
                  id="company_id"
                  name="company_id"
                  {...register("company_id")}
                  defaultValue={activity?.company_id}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                >
                  {companies?.map((company) => (
                    <option
                      key={company?.company_id}
                      value={company?.company_id}
                    >
                      {company?.company_name}
                    </option>
                  ))}
                </select>
              </div>
              <p className="h-4 text-sm text-red-500">
                {errors.company_id && errors.company_id.message}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-6 flex items-center justify-end gap-x-6">
        <button
          type="submit"
          disabled={!isValid}
          className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Modificar
        </button>
        <button
          onClick={handleOpenModal}
          type="button"
          className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Borrar
        </button>
        {isModalOpen && (
          <ConfirmationModal
            onConfirm={handleDeleteActivity}
            onClose={handleCloseModal}
          />
        )}
      </div>
    </form>
  );
}

export default UpdateActivityForm;
