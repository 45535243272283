import { PhotoIcon } from "@heroicons/react/24/solid";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";

import Spinner from "../../../components/loader/Spinner.js";
import ConfirmationModal from "../../../components/modal/ConfirmationModal.js";
import { useActivitiesRepository } from "../../../repositories/activities/activitiesRepository.js";
import { useTemplatesRepository } from "../../../repositories/templates/templatesRepository.js";
import templateSchema from "../schemas/TemplateSchema.js";

import CreateTemplateSlotForm from "./CreateTemplateSlotForm.js";
import UpdateTemplateSlotForm from "./UpdateTemplateSlotForm.js";

function UpdateTemplateForm() {
  const { template_id } = useParams();
  const { templatesRepository } = useTemplatesRepository();
  const [template, setTemplate] = useState(null);
  const [slots, setSlots] = useState([]);
  const { activities } = useActivitiesRepository();
  const [showTemplateSlotForm, setShowTemplateSlotForm] = useState(false);
  const [render, setRender] = useState(false);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchTemplate() {
      const templateData = await templatesRepository.getTemplateById(
        template_id
      );
      setTemplate(templateData);
    }
    fetchTemplate();
  }, [template_id, templatesRepository]);

  const { register, handleSubmit, formState } = useForm({
    mode: "onTouched",
    resolver: zodResolver(templateSchema),
  });

  useEffect(() => {
    const fetchSlots = async () => {
      try {
        const slots = await templatesRepository.getTemplateSlotsByTemplateId(
          template_id
        );
        setSlots(slots);
      } catch (error) {
        console.error("Error fetching slots:", error);
      }
    };

    fetchSlots();
  }, [template_id, templatesRepository, render]);

  const { errors, isValid } = formState;

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleUpdateTemplate = async (form) => {
    try {
      form.modified_at = new Date().toISOString();
      await templatesRepository.updateTemplate(template_id, form);
      toast.success("Plantilla actualizada correctamente");
      navigate("/templates");
    } catch (error) {
      console.error("Error updating template:", error);
      toast.error("Error al actualizar la plantilla");
    }
  };

  const onInvalid = (errors) => console.error(errors);

  const handleDeleteTemplate = async () => {
    try {
      await templatesRepository.deleteTemplate(template_id);
      toast.success("Plantilla eliminada correctamente");
      navigate("/templates");
    } catch (error) {
      console.error("Error deleting template:", error);
      toast.error("Error al eliminar la plantilla");
    }
  };

  const handleSlotCreated = async () => {
    try {
      const slots = await templatesRepository.getTemplateSlotsByTemplateId(
        template_id
      );
      setSlots(slots);
      setShowTemplateSlotForm(false); // Ocultar el formulario después de añadir el slot
    } catch (error) {
      console.error("Error fetching slots:", error);
    }
  };

  if (!template) {
    return <Spinner />;
  }
  return (
    <>
      <form
        onSubmit={handleSubmit(handleUpdateTemplate, onInvalid)}
        encType="multipart/form-data"
      >
        <div className="space-y-12 max-w-4xl mx-auto">
          <div className="border-b border-gray-900/10 pb-12">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Plantilla
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              Datos de la plantilla {template?.template_name}.
            </p>
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-4">
                <label
                  htmlFor="template_name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Nombre de la plantilla
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <input
                      id="template_name"
                      type="text"
                      maxLength={50}
                      defaultValue={template?.template_name}
                      {...register("template_name")}
                      className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    />
                  </div>
                  <p className="h-4 text-sm text-red-500">
                    {errors.template_name && errors.template_name.message}
                  </p>
                </div>
              </div>
              <div className="sm:col-span-4">
                <label
                  htmlFor="composition_name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Composition Name
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <input
                      id="composition_name"
                      type="text"
                      maxLength={50}
                      defaultValue={template?.composition_name}
                      {...register("composition_name")}
                      className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    />
                  </div>
                  <p className="h-4 text-sm text-red-500">
                    {errors.composition_name && errors.composition_name.message}
                  </p>
                </div>
              </div>
              <div className="sm:col-span-4">
                <label
                  htmlFor="composition_name_2"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Composition Name 2
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <input
                      id="composition_name_2"
                      type="text"
                      maxLength={50}
                      defaultValue={template?.composition_name_2}
                      {...register("composition_name_2")}
                      className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    />
                  </div>
                  <p className="h-4 text-sm text-red-500">
                    {errors.composition_name_2 &&
                      errors.composition_name_2.message}
                  </p>
                </div>
              </div>
              <div className="sm:col-span-3">
                <label
                  htmlFor="role"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Actividad
                </label>
                <div className="mt-2">
                  <select
                    id="activity_id"
                    name="activity_id"
                    {...register("activity_id")}
                    defaultValue={template?.activity_id}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                  >
                    {activities?.map((activity) => (
                      <option
                        key={activity?.activity_id}
                        value={activity?.activity_id}
                      >
                        {activity?.activity_name}
                      </option>
                    ))}
                  </select>
                </div>
                <p className="h-4 text-sm text-red-500">
                  {errors.activity_id && errors.activity_id.message}
                </p>
              </div>
              <div className="col-span-full">
                <label
                  htmlFor="cover-photo"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Logo de la plantilla
                </label>
                <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10 max-w-xl">
                  <div className="flex flex-col items-center text-center">
                    {template?.template_logo ? (
                      <img
                        src={template?.template_logo}
                        alt="Foto de la organización"
                        width={125}
                        height={125}
                      />
                    ) : (
                      <PhotoIcon
                        aria-hidden="true"
                        className="mx-auto h-12 w-12 text-gray-300"
                      />
                    )}
                    <div className="mt-4 flex text-sm leading-6 text-gray-600">
                      <label
                        htmlFor="image"
                        className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                      >
                        <span>Upload a file</span>
                        <input
                          id="image"
                          type="file"
                          className="sr-only"
                          accept="image/png, image/jpeg, image/gif"
                          max="10MB"
                          {...register("image", {
                            onChange: (e) => {
                              setIsFileUploaded(true);
                            },
                          })}
                        />
                      </label>
                      <p className="pl-1">or drag and drop</p>
                    </div>
                    <p className="text-xs leading-5 text-gray-600">
                      PNG, JPG, GIF up to 10MB
                    </p>
                    {isFileUploaded && (
                      <p className="text-xs leading-5 text-green-600">
                        Nueva imagen seleccionada
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-6 flex items-center justify-between gap-x-6">
          <div className="flex gap-x-6">
            <button
              disabled={!isValid}
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Modificar
            </button>
            <button
              type="button"
              onClick={handleOpenModal}
              className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Borrar
            </button>
          </div>
          {isModalOpen && (
            <ConfirmationModal
              onConfirm={handleDeleteTemplate}
              onClose={handleCloseModal}
            />
          )}
        </div>
      </form>
      {slots?.length > 0 && (
        <UpdateTemplateSlotForm
          slots={slots}
          render={render}
          setRender={setRender}
        />
      )}
      <div>
        <button
          type="button"
          onClick={() => setShowTemplateSlotForm(!showTemplateSlotForm)}
          className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 mt-10"
        >
          + Agregar bloque
        </button>
      </div>
      {showTemplateSlotForm && (
        <CreateTemplateSlotForm
          setShowTemplateSlotForm={setShowTemplateSlotForm}
          onSlotCreated={handleSlotCreated}
          template_id={template_id}
        />
      )}
    </>
  );
}

export default UpdateTemplateForm;
