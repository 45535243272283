import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import Logo from "../../../assets/4_Logo bz55 NEGRO.png";
import { useExercisesRepository } from "../../../repositories/exercises/exercisesRepository.js";
import { useSessionsRepository } from "../../../repositories/sessions/sessionsRepository.js";
import { useTemplatesRepository } from "../../../repositories/templates/templatesRepository.js";

function CreateSessionPDF() {
  const { session_id } = useParams();
  const { templatesRepository } = useTemplatesRepository();
  const { sessionsRepository } = useSessionsRepository();
  const { exercisesRepository } = useExercisesRepository();
  const [session, setSession] = useState(null);
  const [slots, setSlots] = useState(null);
  const [template, setTemplate] = useState(null);
  const [completeSlots, setCompleteSlots] = useState(null);
  const [exerciseSessions, setExerciseSessions] = useState(null);
  const [exerciseNames, setExerciseNames] = useState(null);
  const [exerciseRepetitions, setExerciseRepetitions] = useState(null);
  const [exerciseMaterial, setExerciseMaterial] = useState(null);
  const [exerciseSequence, setExerciseSequence] = useState([]);
  const pdfRef = useRef();

  const months = {
    ENERO: 0,
    FEBRERO: 1,
    MARZO: 2,
    ABRIL: 3,
    MAYO: 4,
    JUNIO: 5,
    JULIO: 6,
    AGOSTO: 7,
    SEPTIEMBRE: 8,
    OCTUBRE: 9,
    NOVIEMBRE: 10,
    DICIEMBRE: 11,
  };

  const daysOfWeek = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
  ];

  // Función para obtener el día de la semana a partir de una cadena de fecha
  const getDayOfWeek = (sessionDate) => {
    const [day, month, year] = sessionDate.split(" de ");
    const date = new Date(year, months[month.toUpperCase()], day);
    return daysOfWeek[date.getDay()];
  };

  useEffect(() => {
    async function fetchSession() {
      const sessionData = await sessionsRepository.getSessionById(session_id);
      setSession(sessionData);
    }
    fetchSession();
  }, [session_id, sessionsRepository]);

  useEffect(() => {
    async function fetchTemplate() {
      const templateData = await templatesRepository.getTemplateById(
        session?.template?.template_id
      );
      setTemplate(templateData);
    }
    fetchTemplate();
  }, [session?.template?.template_id, templatesRepository]);

  useEffect(() => {
    async function fetchSlot() {
      const slotsData = await templatesRepository.getTemplateSlotsByTemplateId(
        session?.template?.template_id
      );
      const sortedSlots = slotsData?.sort((a, b) => a.order - b.order);
      setSlots(sortedSlots);
    }
    fetchSlot();
  }, [session, sessionsRepository]);

  useEffect(() => {
    if (session) {
      const exerciseSessions = extractExerciseSessions(session);
      setExerciseSessions(exerciseSessions);
    }
  }, [session, sessionsRepository]);

  useEffect(() => {
    if (exerciseSessions) {
      async function fetchExerciseNames() {
        const exerciseNamesData =
          await exercisesRepository.getExercisesNamesByIds(exerciseSessions);
        setExerciseNames(exerciseNamesData);
      }
      fetchExerciseNames();
    }
  }, [exerciseSessions, exercisesRepository]);

  useEffect(() => {
    if (exerciseSessions) {
      const exerciseSessionsRepetitions = extractExerciseRepetitions(session);
      setExerciseRepetitions(exerciseSessionsRepetitions);
    }
  }, [exerciseSessions]);

  useEffect(() => {
    if (exerciseSessions) {
      async function fetchExerciseMaterial() {
        const exerciseMaterialData =
          await exercisesRepository.getExercisesMaterialByIds(exerciseSessions);
        setExerciseMaterial(exerciseMaterialData);
      }
      fetchExerciseMaterial();
    }
  }, [exerciseSessions, exercisesRepository]);

  useEffect(() => {
    if (slots && exerciseNames) {
      let exerciseIndex = 0;
      let sequenceCounter = 1; // Contador para la secuencia global
      const updatedSlots = slots.map((slot) => {
        const updatedExercises = slot.exercises.map((exercise) => {
          const updatedExercise = {
            ...exercise,
            name: exerciseNames[exerciseIndex],
            repetitions:
              exerciseRepetitions[`exercise_session_${exerciseIndex + 1}`] ||
              "",
            sequence: sequenceCounter, // Añadir la secuencia global
          };
          exerciseIndex += 1;
          sequenceCounter += 1; // Incrementar el contador de secuencia
          return updatedExercise;
        });
        return {
          ...slot,
          exercises: updatedExercises,
        };
      });

      setCompleteSlots(updatedSlots);
    }
  }, [slots, exerciseNames]);

  const handleDownloadJson = async (id) => {
    sessionsRepository.exportSessionToJson(id);
  };

  const handleDownloadTxt = async (id) => {
    sessionsRepository.exportSessionToTxt(id);
  };

  const extractExerciseSessions = (session) => {
    const exerciseSessions = Object.keys(session)
      .filter((key) => key.startsWith("exercise_session_"))
      .sort((a, b) => {
        const getOrder = (key) =>
          parseInt(key.match(/exercise_session_(\d+)/)[1], 10);
        return getOrder(a) - getOrder(b);
      })
      .reduce((obj, key) => {
        obj[key] = session[key].value;
        return obj;
      }, {});

    return exerciseSessions;
  };

  const extractExerciseRepetitions = (session) => {
    const exerciseRepetitions = Object.keys(session)
      .filter((key) => key.startsWith("exercise_session_"))
      .sort((a, b) => {
        const getOrder = (key) =>
          parseInt(key.match(/exercise_session_(\d+)/)[1], 10);
        return getOrder(a) - getOrder(b);
      })
      .reduce((obj, key) => {
        obj[key] = session[key].repetitions;
        return obj;
      }, {});
    return exerciseRepetitions;
  };

  const generatePDF = async (dayOfWeek) => {
    try {
      const input = pdfRef.current; // Capturar el contenido del div referenciado

      // Usar html2canvas para capturar el contenido del div como una imagen
      const canvas = await html2canvas(input, {
        logging: true,
        useCORS: true, //to enable cross origin perms
      });
      const imgData = canvas.toDataURL("image/jpeg");

      // Crear un nuevo documento PDF
      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      // Calcular las dimensiones de la imagen para que se ajuste al tamaño A4
      const imgProps = pdf.getImageProperties(imgData);
      const imgWidth = pdfWidth;
      const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;

      // Si la imagen es más alta que el tamaño de la página, escalarla para que encaje en una sola página
      const scale = Math.min(
        pdfWidth / imgProps.width,
        pdfHeight / imgProps.height
      );
      const scaledWidth = imgProps.width * scale;
      const scaledHeight = imgProps.height * scale;

      // Centrar la imagen en la página
      const xOffset = (pdfWidth - scaledWidth) / 2;
      // const yOffset = (pdfHeight - scaledHeight) / 2;
      const yOffset = 0;

      pdf.addImage(
        imgData,
        "JPEG",
        xOffset,
        yOffset,
        scaledWidth,
        scaledHeight,
        "FAST"
      );

      // Obtener el día de la semana a partir de la fecha de la sesión
      dayOfWeek = getDayOfWeek(session.sessionDate);

      const capitalizeWords = (str) => {
        return str
          .toLowerCase()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
      };

      // Aplicar la función a templateData.template_name
      const formattedTemplateName = capitalizeWords(template.template_name);

      // Guardar el PDF
      pdf.save(`${session.day} ${dayOfWeek} - ${formattedTemplateName}.pdf`);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-center">
        <button
          className="m-10 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sticky top-10"
          onClick={generatePDF}
        >
          Generar PDF
        </button>
        <button
          className="m-10 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sticky top-10"
          onClick={() => handleDownloadJson(session_id)}
        >
          Generar JSON
        </button>
        <button
          className="m-10 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sticky top-10"
          onClick={() => handleDownloadTxt(session_id)}
        >
          Generar TXT
        </button>
      </div>
      <div className="py-10 px-10 text-sm" ref={pdfRef}>
        <div className="flex justify-around items-center px-20 py-20">
          <img
            className="w-64"
            src={session?.template?.template_logo}
            alt={session?.template?.name}
          />
          <img className="w-64 h-24" src={Logo} alt={"Logo de la empresa"} />
        </div>
        {completeSlots?.map((slot, index) => (
          <div key={slot?.slot_id} className=" px-10">
            <h2
              className={`bg-black text-white font-bold  ${
                slot.slot_visibility
                  ? "bg-transparent h-4 mt-0 p-0 pb-0"
                  : "h-12 pb-5 mt-6 p-2"
              }`}
            >
              {!slot.slot_visibility && slot?.slot_title}
              {!slot.slot_visibility &&
                slot?.slot_description &&
                ` - ${slot?.slot_description}`}
            </h2>
            {slot?.slot_extra_info_sup && (
              <h2
                className="p-2 font-bold"
                style={{ transform: "translateY(-5px)" }}
              >
                {slot?.slot_extra_info_sup}
              </h2>
            )}
            <ul className="flex flex-col">
              {slot?.exercises?.map((exercise, index) => (
                <li
                  key={exercise?.exercise_id}
                  className={`flex ${index % 2 === 1 ? "bg-gray-100" : ""}`}
                >
                  <div
                    className="flex"
                    style={{ transform: "translateY(-5px)" }}
                  >
                    <p className="p-2 font-semibold w-6">
                      {exercise?.sequence}
                    </p>
                    {exercise?.repetitions && (
                      <p className="p-2 font-semibold text-[12px] min-w-12 self-center text-center">
                        {`- ${exercise?.repetitions} -`}
                      </p>
                    )}
                    <p className="p-2 font-semibold">
                      {exercise?.name?.toUpperCase()}
                    </p>
                  </div>
                </li>
              ))}
            </ul>
            {slot?.slot_extra_info_sub && (
              <h2
                className="p-2 font-bold"
                style={{ transform: "translateY(-5px)" }}
              >
                {slot?.slot_extra_info_sub}
              </h2>
            )}
          </div>
        ))}
        <div className="px-10">
          <h2 className="bg-black text-white p-2 font-bold pb-5 mt-6">
            MATERIAL
          </h2>
          <ul
            className={`grid ${
              exerciseMaterial?.length > 1 ? "grid-cols-2" : ""
            }`}
          >
            {exerciseMaterial
              ?.filter(
                (material) => !material.toLowerCase().includes("body weight")
              )
              .sort((a, b) => a.localeCompare(b))
              .map((material, index) => {
                const isHighlighted = [0, 1, 4, 5, 8, 9, 12, 13].includes(
                  index
                ); // Índices 0, 1, 4, 5 corresponden a los elementos 1, 2, 5, 6
                return (
                  <li
                    key={`${material}${index}li`}
                    className={`flex ${isHighlighted ? "" : "bg-gray-100"}`}
                  >
                    <div
                      className="flex"
                      style={{ transform: "translateY(-5px)" }}
                    >
                      <p className="p-2 font-semibold">
                        - {material.toUpperCase()}
                      </p>
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default CreateSessionPDF;
