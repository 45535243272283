import { PhotoIcon, UserCircleIcon } from "@heroicons/react/24/solid";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";

import Spinner from "../../../components/loader/Spinner.js";
import ConfirmationModal from "../../../components/modal/ConfirmationModal.js";
import { useCompaniesRepository } from "../../../repositories/companies/companiesRepository.js";
import companySchema from "../schemas/CompanySchema.js";

function UpdateCompanyForm() {
  const { company_id } = useParams();
  const { companiesRepository } = useCompaniesRepository();
  const [company, setCompany] = useState(null);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchCompany() {
      const companyData = await companiesRepository.getCompanyById(company_id);
      setCompany(companyData);
    }
    fetchCompany();
  }, [company_id, companiesRepository]);

  const { register, handleSubmit, formState } = useForm({
    mode: "onTouched",
    resolver: zodResolver(companySchema),
  });

  const { errors, isValid } = formState;

  const handleUpdateCompany = async (form) => {
    try {
      form.modified_at = new Date().toISOString();
      await companiesRepository.updateCompany(company_id, form);
      toast.success("Organización actualizada correctamente");
      navigate("/companies");
    } catch (error) {
      console.error("Error updating company:", error);
      toast.error("Error al actualizar la organización");
    }
  };

  const onInvalid = (errors) => console.error(errors);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleDeleteCompany = async () => {
    try {
      await companiesRepository.deleteCompany(company_id);
      toast.success("Organización eliminada correctamente");
      navigate("/companies");
    } catch (error) {
      console.error("Error deleting company:", error);
      toast.error("Error al eliminar la organización");
    }
  };

  if (!company) {
    return <Spinner />;
  }
  return (
    <form
      onSubmit={handleSubmit(handleUpdateCompany, onInvalid)}
      encType="multipart/form-data"
    >
      <div className="space-y-12 max-w-4xl mx-auto">
        <div className="border-b border-gray-900/10 pb-12">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Modificar una organización
          </h2>
          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-4">
              <label
                htmlFor="company_name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Nombre de la franquicia
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <input
                    id="company_name"
                    type="text"
                    maxLength={50}
                    defaultValue={company?.company_name}
                    {...register("company_name")}
                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  />
                </div>
                <p className="h-4 text-sm text-red-500">
                  {errors.company_name && errors.company_name.message}
                </p>
              </div>
            </div>
            <div className="sm:col-span-4">
              <label
                htmlFor="comercial_name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Nombre comercial
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <input
                    id="comercial_name"
                    type="text"
                    defaultValue={company.comercial_name}
                    maxLength={50}
                    {...register("comercial_name")}
                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  />
                </div>
                <p className="h-4 text-sm text-red-500">
                  {errors.comercial_name && errors.comercial_name.message}
                </p>
              </div>
            </div>
            <div className="sm:col-span-4">
              <label
                htmlFor="CIF"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                CIF
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <input
                    id="CIF"
                    type="text"
                    defaultValue={company.CIF}
                    maxLength={9}
                    {...register("CIF")}
                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  />
                </div>
                <p className="h-4 text-sm text-red-500">
                  {errors.CIF && errors.CIF.message}
                </p>
              </div>
            </div>
            <div className="sm:col-span-4">
              <label
                htmlFor="address"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Dirección
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <input
                    id="address"
                    type="text"
                    defaultValue={company.address}
                    maxLength={50}
                    {...register("address")}
                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  />
                </div>
                <p className="h-4 text-sm text-red-500">
                  {errors.address && errors.address.message}
                </p>
              </div>
            </div>
            <div className="sm:col-span-4">
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Email
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <input
                    id="email"
                    type="email"
                    defaultValue={company.email}
                    maxLength={50}
                    {...register("email")}
                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  />
                </div>
                <p className="h-4 text-sm text-red-500">
                  {errors.email && errors.email.message}
                </p>
              </div>
            </div>
            <div className="sm:col-span-4">
              <label
                htmlFor="phone_number"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Número de teléfono
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <input
                    id="phone_number"
                    type="text"
                    defaultValue={company.phone_number}
                    {...register("phone_number")}
                    maxLength={15}
                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  />
                </div>
                <p className="h-4 text-sm text-red-500">
                  {errors.phone_number && errors.phone_number.message}
                </p>
              </div>
            </div>
            <div className="col-span-full">
              <label
                htmlFor="cover-photo"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Logo de la empresa
              </label>
              <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10 max-w-xl">
                <div className="flex flex-col items-center text-center">
                  {company.company_logo ? (
                    <img
                      src={company.company_logo}
                      alt="Foto de la organización"
                      width={125}
                      height={125}
                    />
                  ) : (
                    <PhotoIcon
                      aria-hidden="true"
                      className="mx-auto h-12 w-12 text-gray-300"
                    />
                  )}
                  <div className="mt-4 flex text-sm leading-6 text-gray-600">
                    <label
                      htmlFor="image"
                      className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                    >
                      <span>Upload a file</span>
                      <input
                        id="image"
                        type="file"
                        className="sr-only"
                        accept="image/png, image/jpeg, image/gif"
                        max="10MB"
                        {...register("image", {
                          onChange: (e) => {
                            setIsFileUploaded(true);
                          },
                        })}
                      />
                    </label>
                    <p className="pl-1">or drag and drop</p>
                  </div>
                  <p className="text-xs leading-5 text-gray-600">
                    PNG, JPG, GIF up to 10MB
                  </p>
                  {isFileUploaded && (
                    <p className="text-xs leading-5 text-green-600">
                      Nueva imagen seleccionada
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-6 flex items-center justify-end gap-x-6">
        <button
          disabled={!isValid}
          className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Modificar
        </button>
        <button
          type="button"
          onClick={handleOpenModal}
          className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Borrar
        </button>
        {isModalOpen && (
          <ConfirmationModal
            onConfirm={handleDeleteCompany}
            onClose={handleCloseModal}
          />
        )}
      </div>
    </form>
  );
}

export default UpdateCompanyForm;
